<template>
    <b-container>

        <h2><b>Removal of Student Property</b></h2>

        <p>In the event of an emergency or other exigent circumstance, Island Condos and its agents may remove
            students’ belongings from the Premises for cleaning, inspection, repair, storage, and/or protection.
            Island Condos does not assume any obligation or liability for loss or damage to items of personal
            property that may occur during removal of student belongings. Student housing may destroy and discard
            personal belongings such as perishable items, alcohol, illegal drugs and drug-related paraphernalia,
            explosives, and hazardous materials. Should Island Condos discover items that raise concern for
            immediate safety or a significant violation of housing policy or federal law, this matter may be referred to
            the appropriate authorities.</p>

        <h2><b>Abandoned Property</b></h2>

        <p>Any belongings left anywhere in the residence (including common areas) after the residency agreement
            ends or is cancelled are classified as abandoned property. Unsanctioned abandoned property will be
            held for <b><u>14</u></b>
            days. After 14 days, items may be donated to charitable organizations, sold or disposed of.
        </p>

        <h2><b>Commercial Enterprise</b></h2>

        <p>You may not conduct any activity for business or financial gain from a residential facility or any University
            property. Additionally, sales, solicitation, and peddling are strictly prohibited.
        </p>

        <h2><b>Insurance</b></h2>

        <p>Island Condos does not assume any obligation or liability for loss or damage to items of personal
            property which may occur in its buildings or on its grounds, before, during or after the terms of
            residency. This includes but is not limited to damage, loss, fire, water damage, theft, flooding, etc.
            Students are urged to purchase renter's insurance for protection against loss or property damage. We
            encourage you to research options on your own but, if you would like guidance on this issue, please
            contact us at<a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>.
        </p>

        <h2>Resource Guide</h2>

        <p>Linked below is a compressive guide for medical, housing, crisis, etc. resources in DC, MD and VA.
        </p>

        <p><a href="https://example.com/ComprehensiveResourceGuideForDMV.pdf">Resource Guide</a></p>

        <h2><b>Additional Provisions</b></h2>

        <p>Residents are subject to all relevant Island Condos policies, which may be amended or updated from
            time to time in the sole discretion of Island Condos. Student housing retains the unilateral right to
            terminate the residential agreement at any time. Failure of either party to insist upon strict performance
            of any of the Terms or Conditions herein shall not be deemed a waiver of any rights or remedies of either
            party and shall not be deemed a waiver of any subsequent breach or default in any of the Terms or
            Conditions herein. Island Condos assumes no responsibility for failure to perform any terms or
            conditions of this agreement due to any force majeure. For purposes of this agreement, the term "force
            majeure" shall mean fire, earthquake, flood, hurricane, or other significant weather event or natural
            disaster, act of God, strikes, work stoppages or other labor disturbances, riots or civil commotions,
            litigation, war or other act of any foreign nation, plague, epidemic, pandemic, power of government or
            governmental agency or authority, state of emergency, or any other cause like or unlike any cause
            15
            mentioned above, whether natural or man-caused, which is beyond the control or authority of student
            housing. Island Condos does not discriminate in any of its programs, procedures, or practices against any
            person on the basis of age, citizenship, color, disability, national origin, political affiliation, race, religion,
            sex, sexual orientation, gender identity or status as a veteran.</p>


        <b-col>
            <ValidationObserver ref="formModalValidate">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <div class="mr-3">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="form.status"
                            name="checkbox-1"
                            value="accepted"
                            :unchecked-value="null"
                        >
                            I have read, understood, and accept
                        </b-form-checkbox>
                        <b-form-invalid-feedback v-if="errors[0]"
                                                 v-html="errors[0]">

                        </b-form-invalid-feedback>
                    </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                    <b-button @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </ValidationObserver>
        </b-col>


    </b-container>
</template>
<script>
export default {

    data() {
        return {
            form: {
                status:null,
            }
        }
    },
    methods:{
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
    }
}
</script>
<style>
@import ".././Style/Style.css";
</style>
