<template>
    <div class="container mt-5 border p-3">
        <b-row class="justify-content-center mb-4">
            <b-col cols="12" md="6" class="text-center">
                    <b-form-group :label="$t('Photo upload is mandatory as the Student Housing ID card will be printed')">
                        <div class="d-flex custom-file-upload">
                            <b-form-file
                                accept="image/*"
                                @change="onFileChange"
                                placeholder="Select a profile picture"
                                ref="fileInput"
                                multiple></b-form-file>
                            <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                        </div>
                    </b-form-group>
            </b-col>
        </b-row>
        <div class=" d-flex justify-content-center align-items-center" v-if="img">
            <b-col cols="12" md="6" class="text-center">
                <div class="cropper-container">
                    <cropper
                        class="cropper"
                        :src="img"
                        :stencil-props="{
                          aspectRatio: 1,
                          movable: true,
                          resizable: true
            }"
                        @change="change"
                    ></cropper>
                </div>
            </b-col>
            <b-col cols="12" md="4" class="text-center" v-if="croppedImage">
                <img
                    :src="croppedImage"
                    alt="Kırpılmış Görsel"
                    class="img-thumbnail cropped-image"
                />
            </b-col>
        </div>
    </div>
</template>


<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
    components: {
        Cropper,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            croppedImage: null,
        };
    },
    computed: {
        img: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
    methods: {
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.img = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        change({canvas }) {
            if (canvas) {
                this.croppedImage = canvas.toDataURL(); // Kırpılmış görseli alır
            }
        },
    },
};

</script>



<style scoped>
.cropper-container {
    width: 100%;
    max-width: 250px; /* Cropper genişliği küçültüldü */
    height: 250px; /* Cropper yüksekliği küçültüldü */
    margin: 0 auto;
    border: 1px solid #ddd;
}

.cropper {
    width: 100%;
    height: 100%;
}

.cropped-image {
    width: 100%;
    max-width: 150px; /* Kırpılmış görsel genişliği küçültüldü */
    height: auto;
    margin: 0 auto;
}
</style>


