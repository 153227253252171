<template>
    <b-container>

        <h2>Tobacco Use</h2>

        <p>Please note that the legal smoking age in Washington, DC is 21 years old. Smoking cigarettes and/or
            vapes in personal rooms or common areas of student housing is strictly prohibited.
        </p>

        <p>You may smoke outside but you must be at least 20 feet away from the building. Cigarette butts must be
            disposed of properly.
        </p>

        <p>If you are found smoking within 20 feet of the building or not disposing of cigarettes properly:</p>

        <p>First infraction: verbal warning</p>
        <p>Second infraction: written warning and probation
        </p>
        <p>Third infraction: forfeiture of security deposit and removal from Island Condos</p>

        <h2>Disorderly Conduct</h2>

        <p>Disorderly conduct is conduct that is boisterous, unruly or is otherwise disruptive to the living
            community. Examples include but are not limited to:</p>

        <ol class="numbered-list">
            <li>Posting/writing harassing materials.
            </li>
            <li>Pranks which include theft or damage to property, violate other policies, or put others in danger are strictly
                prohibited and taken very seriously.
            </li>
            <li>Depositing body fluids, including but not limited to: vomiting, urinating, or defecating in public areas or
                inappropriate locations is prohibited.
            </li>
            <li>Engaging in assaultive behavior.</li>
            <li>Making threats of harm directed at others.</li>
            <li>Harassment directed at other residents, staff, students, or guests.</li>
        </ol>

        <p>Penalties for disorderly conduct are dependent on the nature of the violation and can range from a
            written warning to immediate expulsion from student housing. Any infraction that is a violation of
            Washington, DC law will be reported to local law enforcement.
        </p>

        <h2>Weapons</h2>

        <p>The possession and/or use of firearms and other dangerous weapons is prohibited. This includes but is
            not limited to firearms, replicas of firearms, paintball guns, pellet guns, BB guns, Airsoft BB guns, bows
            and arrows, ninja stars, knives and swords.
        </p>

        <p>The possession and/or use of dangerous weapon parts and/or accessories is prohibited. This includes,
            but is not limited to parts of guns, ammunition shells, shell casings, and plastic or metal pellets.
            Additionally, you may not display or portray as real any object that resembles a dangerous weapon.</p>

        <p>If you are found with a weapon, part of a weapon or weapon accessories:</p>

        <ol class="numbered-list">
            <li>Your security deposit will be forfeited.</li>
            <li>You will be immediately and permanently banned from Island Condos.
            </li>
            <li>If a weapon has been obtained illegally or the make of the weapon is prohibited by law, law
                enforcement will be notified.
            </li>
        </ol>

        <h2>Fire Safety</h2>

        <p>Residents must adhere to the Washington, DC <a
            href="https://code.dccouncil.gov/us/dc/council/code/titles/6/chapters/7/">fire code</a>. It is the responsibility of the tenant to acquaint
            themselves with the code in its entirety, but some common violations are:
        </p>

        <ol class="numbered-list">
            <li>Residents are prohibited from burning/lighting candles or incense in rooms and/or common
                areas.
            </li>
            <li>Wax heating electronics are prohibited.</li>
            <li>Residents are prohibited from having firecrackers/fireworks within, out of, or adjacent to residential
                communities.
            </li>
            <li>Setting fires in and around the residence halls is prohibited.</li>
            <li>Covering, disconnecting, or hanging items from smoke detectors is prohibited.</li>
            <li>Residents are not permitted to keep non-potted vegetation (i.e., Christmas trees, wreaths, straw, wood, and/or
                bark) in their rooms.
            </li>
            <li>Only circuit breaker extension cords are permitted.</li>
            <li>Residents may not cover any lighting fixture with any material including cloth, paper, etc.</li>
            <li>Items should not be affixed to or hung from room ceilings and/or pipes. These items include but are not
                limited to ceiling fans, clotheslines, flags, posters, string lights, etc.
            </li>
            <li>Gasoline, propane, lighter fluid, nitrous oxide, liquid nitrogen, turpentine, dry cleaning fluid, and any
                other highly flammable chemicals are prohibited.
            </li>
            <li>Rooms must have a clear path from the door to the other side of the room.</li>
        </ol>

        <p class="indent">If candles, incense or open flame are a necessary part of your firmly held religious beliefs,
            please complete the <a
                href="https://docs.google.com/document/d/1qZWlhTaY3SKR2hwdhX8eti4SJbKHbpfiO8_Az8MSPEQ/edit?usp=sharing">Religious
                Exemption Request Form</a>.</p>

        <p class="indent">Penalties for violating fire safety policies are dependent on the nature of the violation and can range
            from a verbal warning to immediate expulsion from student housing. Any infraction that is a
            violation of Washington, DC law will be reported to local law enforcement authorities.
        </p>

        <h3 class="indent">Tampering with Fire Safety Equipment</h3>

        <p class="indent">Residents are prohibited from tampering with fire safety equipment or refusing to evacuate during
            an alarm are serious violations of student housing policy and state law. Tampering with fire safety
            equipment may result in disciplinary action, exclusion from student housing, and even criminal
            charges. This includes but is not limited to:</p>

        <ul class="bullet-list">
            <li>Removing batteries from any alarm system.</li>
            <li>Disconnecting wiring of any alarm system.</li>
            <li>Muffling the sound of any alarm system.</li>
            <li>Removing hardware from fire doors to prevent proper latching/closing.</li>
            <li>Propping fire doors open.</li>
            <li>Blocking access to fire panels.</li>
            <li>Covering/removing smoke detectors.</li>
        </ul>


        <b-col>
            <ValidationObserver ref="formModalValidate">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <div class="mr-3">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="form.status"
                            name="checkbox-1"
                            value="accepted"
                            :unchecked-value="null"
                        >
                            I have read, understood, and accept
                        </b-form-checkbox>
                        <b-form-invalid-feedback v-if="errors[0]"
                                                 v-html="errors[0]">

                        </b-form-invalid-feedback>
                    </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                    <b-button @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </ValidationObserver>
        </b-col>


    </b-container>
</template>
<script>
export default {

    data() {
        return {
            form: {
                status:null,
            }
        }
    },
    methods:{
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
    }
}
</script>
<style>
@import ".././Style/Style.css";
</style>
