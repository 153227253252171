    <template>
    <b-container>

        <h2>Sexual Harassment/Violence</h2>

        <p>Sexual harassment, sexual violence, domestic violence and/or stalking are strictly prohibited. Occupants
            are responsible for adherence to the Bay Atlantic University and Mentora College policies on these
            matters.
        </p>

        <p>Each incident will be reviewed individually and penalties for infractions will be determined on a
            case-by-case basis ranging from a written warning to immediate expulsion from student housing.</p>

        <h2>Bias Incidents</h2>

        <p>Bias incidents are non-criminal activity committed against a person or property that is motivated, in
            whole or in part, by the offender’s bias against a victim’s real or perceived expression of race, ethnicity,
            national origin, sex, gender identity or expression, sexual orientation, disability (mental or physical), age,
            political affiliation, religion or any other physical or cultural characteristics. These actions can be physical,
            verbal, or electronic messages.</p>

        <p>Diversity, equity, and inclusion are important cornerstones of Bay Atlantic University and Mentora
            College and allegations of bias incidents are taken very seriously and are not tolerated under any
            circumstances. If you believe that you are a victim of or witness to a bias incident, please complete the <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdWPclBT8SPhuxma5edcUaz5CEWcTjJfllo9dSt30vf3yr9ZA/viewform?usp=sf_link">Bias
                Incident Report Form</a> and forward it to <a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>.
        </p>

        <p>Reports will be reviewed by a committee and if it is determined that you are guilty:
            </p>

        <ol class="numbered-list">
            <li>Your security deposit will be forfeited.
            </li>
            <li>You will be permanently banned from Island Condos.</li>
        </ol>

        <h3><b>Why You Should Report Bias or Hate</b></h3>

        <p>The purpose of this reporting system is to provide support and resources to involved parties but, also, to
            educate the community about behaviors that are deemed unacceptable in their new/temporary country.
            We want to make it clear that intolerance has no place at this institution and all reports will be taken
            seriously.
        </p>

        <p>If you are a witness to or a victim of bias or hate, please complete the incident report. Your name will
            NEVER be revealed but, if you would like to make this report anonymously, you can do so by typing:
        </p>

        <p>Name: anonymous</p>
        <p>Email: <a href="mailto:anonymous@bau.edu">anonymous@bau.edu</a></p>

        <p>In the form because they are required categories.</p>

        <h2>Hate Crimes</h2>

        <p>A hate crime occurs when a perpetrator engages in criminal behavior or acts against an individual,
            property, organization, or group based on the offender’s perceptions of the victims’ race, ethnicity,
            national origin, sex, gender identity or expression, sexual orientation, physical disability, or religion.</p>

        <p>Types of crimes may include but are not limited to vandalism, graffiti, threatening phone calls, online
            harassment, physical harassment, arson, battery, or physical attacks.</p>

        <p>If you are a victim of or a witness to a hate crime, you are encouraged to report it to security
            immediately.
        </p>

        <p>If you are accused of a hate crime, law enforcement authorities will be notified. If you are found guilty of
            a hate crime:</p>

        <ol class="numbered-list">
            <li>You will be immediately and permanently removed from the student housing.
            </li>
            <li>Your security deposit will be forfeited.
            </li>
            <li>This will not represent the end of your housing contract and you will be expected to remit
                payment for the remainder or your contract or, if you have prepaid, your housing fees will not be
                returned.
            </li>
        </ol>

        <h2>Airport Drop Off</h2>

        <p>It is possible to arrange airport transit through Island Condos. If you require transport to the airport, your
            request must be submitted in writing at least 7 days prior to departure. The fee is $150 and you should
            request this service sending an email to <a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a> with the
            following information:</p>

        <ol class="numbered-list">
            <li>Your full name</li>
            <li>Date of travel</li>
            <li>Time of travel</li>
            <li>Airline</li>
        </ol>

        <h2><b>Criminal Conviction or Protective Order</b></h2>

        <p>If you are arrested and/or convicted of any felony or any crime requiring your registration on the Sex
            Offender or Crimes Against Minors Registry (SOR), or if you become subject to a protective order at any
            time during your residency at Island Condos, you are required within seventy-two (72) hours of the
            arrest and/or conviction or entry of the protective order, to notify Island Condos in writing at <a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>.
        </p>

        <p>Island Condos reserves the right to remove any resident from the premises if, in its sole judgment, you
            may pose a threat of substantial harm to other residents or to property or if it is determined that you
            were offered residency under false pretenses.</p>

        <h2><b>Failure to Vacate</b></h2>

        <p>If a student fails to vacate at the agreed upon end of their residency, for any reason, the Island Condos
            may exercise any or all of the following options: (a) remove the student and student's possessions from
            the Premises; (b) change the door locks and bill the student; (c) bill the student for all damages suffered
            by student housing as a result of failure to vacate; for example, payment of attorney fees and expenses.
            In-house counsel fees will be assessed at the prevailing hourly rates charged by outside counsel.</p>


        <p>After 14 days, if you have still failed to vacate or make arrangements with Island Condos expressed,
            written approval, your property will be considered abandoned</p>

        <h2><b>Subletting and Overnight Guests</b></h2>

        <p>You may not sublet the Premises or any part thereof. Your housing unit should be solely used as your
            primary residence. Overnight stays are not permitted, and guest visits should be aligned with Island
            Condos guest policy previously outlined.</p>



        <b-col>
            <ValidationObserver ref="formModalValidate">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <div class="mr-3">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="form.status"
                            name="checkbox-1"
                            value="accepted"
                            :unchecked-value="null"
                        >
                            I have read, understood, and accept
                        </b-form-checkbox>
                        <b-form-invalid-feedback v-if="errors[0]"
                                                 v-html="errors[0]">

                        </b-form-invalid-feedback>
                    </div>
                </ValidationProvider>
                <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                    <b-button @click="next" variant="primary" class="mr-3">{{
                            $t('next')
                        }}
                    </b-button>
                </div>
            </ValidationObserver>
        </b-col>


    </b-container>
</template>
<script>
export default {

    data() {
        return {
            form: {
                status:null,
            }
        }
    },
    methods:{
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
    }
}
</script>
<style>
@import ".././Style/Style.css";
</style>
