<template>
        <b-container>

                <h1 class="title">RESIDENT HANDBOOK</h1>

                <p><b>Welcome</b></p>



                <p>Thank you for choosing Island Condos, we’re delighted to have you! We hope that you enjoy your stay
                    with us and, to help you get acclimated to our environment, we have compiled some helpful information
                    for you. Please take some time to read over our policies, regulations, and services.
                </p>

                <p>Island Condos Student Housing is committed to maintaining the health and safety of each student on our
                    premises and strives to foster an atmosphere of positive engagement and mutual respect. To that end,
                    every resident is expected to review this handbook in its entirety and abide by the rules and regulations
                    herein. Of course, it is not possible to apply an official policy to every situation that could occur; in
                    instances where there is no written policy, it is important for each member of the community to think
                    critically about their behavior and its potential impact on others in our shared living spaces. Policies may
                    be updated without notice.
                </p>

                <p>Additionally, your occupancy is predicated on the understanding that you are renting a space in student
                    housing, not any specific room and/or building.
                </p>


                <p>Housing management is available to answer any questions or address any issues that you may have.
                    Please contact us at<a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a></p>

                <h2>Room Types and Fees</h2>

                <table>
                    <tr>
                        <th>Room Type</th>
                        <th>Type of Bed</th>
                        <th>Monthly Fee</th>
                    </tr>
                    <tr class="highlight">
                        <td>Double room with private balcony</td>
                        <td>Day bed</td>
                        <td>$1050</td>
                    </tr>
                    <tr>
                        <td>Double room</td>
                        <td>Day bed</td>
                        <td>$920</td>
                    </tr>
                    <tr class="highlight">
                        <td>Double room</td>
                        <td>Bunk bed</td>
                        <td>$820</td>
                    </tr>
                    <tr>
                        <td>Triple room</td>
                        <td>Day bed</td>
                        <td>$600</td>
                    </tr>
                    <tr class="highlight">
                        <td>Quadruple room</td>
                        <td>Bunk bed</td>
                        <td>$500</td>
                    </tr>
<!--                    <tr>
                        <td>Double Room (Building 632)*</td>
                        <td>Day bed</td>
                        <td>$1050</td>
                    </tr>
                    <tr class="highlight">
                        <td>Double Room (Building 632)*</td>
                        <td>Bunk bed</td>
                        <td>$920</td>
                    </tr>-->
                </table>

                <p>Common areas including kitchens, bathrooms, and lounges, are shared with other residents. The exact
                    number of occupants will depend on your room type.</p>

                <h2>Payments</h2>

                <p>Payments are due on the 1<sup>st</sup> day of every month. Tenants may submit payment through Zelle, or
                <a href="https://ois.bau.edu/fi/epayment/housing/ln/en">the online payment portal</a>
                . Cash and payments by check are also accepted in the Finance Office located on the 9<sup>th</sup>
                floor of the Bay Atlantic University campus. If your account is not resolved by the 5<sup>th</sup>
                day of the month, you will be charged a $50 late fee and that fee will increase by $50 every 5 days.</p>

                <p>Example:</p>
                <ul>
                    <li>September 1-5: $50 late fee</li>
                    <li>September 6-10: $100 late fee</li>
                </ul>


            <p>
                If you have not resolved your account by the 15<sup>th</sup>  you will be asked to vacate the property.
            </p>

                <h2>Room Assignments</h2>

                <p>Student Housing reserves all rights concerning any room assignment, reassignment, adjustment and/or
                    consolidation of student spaces. Student Housing further reserves the right to assign tenants to
                    temporary space, when necessary, and to reassign the tenant to permanent space when it becomes
                    available. If a vacancy occurs in the room to which you are assigned, you must maintain the open space
                    so that it is ready for a new occupant at any time.
                </p>

                <h2>Failure to Maintain Open Space</h2>

                <p>If it is discovered that you have not maintained the temporarily open space in your room, your security
                    deposit will be forfeited. Additionally, you may be charged the full cost of the open space if a new
                    occupant is unable to move in as a consequence.
                </p>

                <h2>Room Changes</h2>

                <p>Students may request room changes for the following reasons:</p>

                <ol>
                    <li><b>Genuine safety concern:</b> A resident in need of a room change due to safety concerns should contact the
                        Director of Student Services at <a href="mailto:rmckie@bau.edu">rmckie@bau.edu</a> immediately.
                    </li>
                    <li><b>Medical need:</b> Residents who have a medical need in which a room change is necessary, please contact the
                        Director of Student Services directly at <a href="mailto:rmckie@bau.edu">rmckie@bau.edu</a>.
                    </li>
                    <li><b>Roommate conflict:</b> There may be times when a student’s living situation becomes challenging due to
                        issues with their roommates or community. Students experiencing a roommate conflict may be asked to work with
                        student housing staff to mediate the dispute before a room change is approved.
                    </li>
                    <li><b>Desire to live in a different room type:</b> Some students may wish to move to a more spacious or cozier
                        room.
                    </li>
                </ol>

                <p><a href="https://docs.google.com/document/d/1OuWSKGPQzz3ZAG7q0hdM92ElE-OqTsTrXneMR7V__sU/edit"><b>Room Change
                    Request Form</b></a></p>

                <p><b>Please note:</b> Island Condos is committed to diversity and inclusion, and room change requests based on
                    national origin, race, religion, sexual orientation, age, or any other discriminatory reason <b><u>WILL NOT be
                        entertained.</u></b></p>
        <b-col>
         <ValidationObserver ref="formModalValidate">
            <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                <div class="mr-3">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="form.status"
                        name="checkbox-1"
                        value="accepted"
                        :unchecked-value="null"
                    >
                        I have read, understood, and accept
                    </b-form-checkbox>
                    <b-form-invalid-feedback v-if="errors[0]"
                                             v-html="errors[0]">

                    </b-form-invalid-feedback>
                </div>
            </ValidationProvider>
            <div class="d-flex justify-content-center  align-center mt-3 mb-3">
                <b-button @click="next" variant="primary" class="mr-3">{{
                        $t('next')
                    }}
                </b-button>
            </div>
            </ValidationObserver>
        </b-col>


        </b-container>
</template>
<script>
export default {

    data() {
        return {
            form: {
                status:null,
            }
        }
    },
    methods:{
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.$emit('next', 1)
            }

        },
    }
}
</script>
<style>
@import ".././Style/Style.css";
</style>
